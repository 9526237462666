import React, { useRef } from 'react'
import { Card, Paper,TextField,InputAdornment,IconButton,Tooltip ,List,ListItem,ListItemText,Button,FormControl,InputLabel, Dialog, DialogContent,Box, Popover,Typography,Popper,StyledPopperDiv, DialogTitle, DialogActions, LinearProgress } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import axios, * as others from 'axios'
import {useState,useEffect} from 'react'
import { getAuth } from "firebase/auth";
import { auth, db, uid } from 'index';
import './agentstyles.css'
import { addDoc, collection, query, where,deleteDoc, getDocs,getDoc, serverTimestamp,onSnapshot, doc,updateDoc, setDoc, arrayUnion,} from '@firebase/firestore';
import  { getStorage, ref, uploadBytes,getDownloadURL, deleteObject  } from 'firebase/storage';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ImpurityPredictionOutputComp from 'views/Rebolt/ImpurityPrediction/ImpurityPreidictionOutcomp';
import AtommappingOutputcomponent from 'views/Rebolt/AtomMapping/Atommappingoutputcomponent';
import CloseIconbutton from 'ui-component/Buttons/CloseIconbutton';
import DialogBox from 'ui-component/Dialogs/DialogBox';
import ConditionRecomOutcomp from 'views/Rebolt/ConditionRecommendations/conditionRecomOutcomp';
import ForwardReactionOutputComp from 'views/Rebolt/ForwardReaction/ForwardReactionOutputcomp';
import Retrooutcomp from 'views/Rebolt/Retrosynthesis/Retrooutcomp';
import { EditIconButton,DeleteIconButton,ArchiveIconButton,DetailconButton, } from 'ui-component/Buttons/IconButtons';
import Chattoolbarcomp from 'ui-component/Buttons/Chattoolbarcomp';
import DeleteWarning  from 'ui-component/Dialogs/DeleteWarining'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { ColorButton } from 'ui-component/Buttons/ColorButton';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FileDisplay from './FileDisplay';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { borderRadius } from '@mui/system';
import Hideicon from 'assets/icon';



const ActivereqComponent=()=>{
  return(
    <div style={{baackgroundColor:'#edf1f7', width:'90%', background:'white',padding:'10px', fontSize:'1rem',fontWeight:'400', textAlign:'center',borderRadius:'5px'}}>
      <Typography color='secondary'>An active request is being Processed please wait <span  className='loaderwidth'></span></Typography>
    </div>
  )
}




const Outputfun = ({ collects, Expid }) => {
    const [component, setComponent] = useState(<LinearProgress color='secondary'/>);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const ref = doc(db, collects, Expid);
          const res = await getDoc(ref);
  
          if (res.data()?.status !== 'pending') {
            if (collects === 'impurities') {
              setComponent(<ImpurityPredictionOutputComp eid={Expid} Agsc={true} />);
            } else if (collects === 'atommapping') {
              setComponent(<AtommappingOutputcomponent eid={Expid} Agsc={true} />);
            } else if (collects === 'condition_recommendation') {
              setComponent(<ConditionRecomOutcomp eid={Expid} Agsc={true} />);
            } else if (collects === 'forward_reaction') {
              setComponent(<ForwardReactionOutputComp eid={Expid} Agsc={true} />);
            } else if (collects === 'retrosynthesis') {
              if(res.data()?.status !== '0% completed'){
              setComponent(<Retrooutcomp eid={Expid} Agsc={true} template="retrosynthesis" />);
              }
              else{
                setComponent(<Typography>The Experiment Submitted is still Pending...</Typography>);
              }
            } else if (collects === 'retrosynthesis_tf') {
              setComponent(<Retrooutcomp eid={Expid} Agsc={true} template="retrosynthesis_tf" />);
            } else if (collects === 'retrosynthesis_st') {
              setComponent(<Retrooutcomp eid={Expid} Agsc={true} template="retrosynthesis_st" />);
            } else {
              setComponent(<Typography>working .....</Typography>);
            }
          } else {
            setComponent(<div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div style={{marginTop:'20%', color:'#323259',width:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Typography sx={{fontSize:'1.5rem'}}>The Experiment Submitted is still Pending...</Typography></div>
            </div>);
          }
        } catch (error) {
          setComponent(<Typography>Error fetching data: {error.message}</Typography>);
        }
      };
  
      fetchData();
    }, [collects, Expid]);
  
    return component;
  };

  const BoldText = ({ text }) => {
    const processText = (text) => {
      const parts = text.split(/(\*\*.*?\*\*)/); // Split by the **text**
      return parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
          return <strong key={index}>{part.slice(2, -2)}</strong>; // Remove ** and make bold
        }
        return part; // Return normal text
      });
    };
  
    return <p style={{margin:'0px'}}>{processText(text)}</p>;
  };




const AgentsScreen2 = () => {
  
   const [sendMsg, setsendMsg] = useState('')
   const [convodata,setConvodata]= useState({})
   const [token,setToken]= useState('')
   const [chatlistarr,setchatlistarr] = useState([])
   const [currentChatid,setcurrentChatid] = useState('')
   const [lastChatid,setlastChatid]=useState(localStorage.getItem('lastChatid'))
   const [convid,setconvid]= useState(localStorage.getItem('lastChatid'))
   const [currentChat,setCurrentChat] = useState([])
   const [Expdialogopen,setExpdialogopen] = useState(false)
   const [EditName,setEditName] = useState('')
   const [Delwaropen,setDelwaropen] = useState(false)
   const [Did,setDid] =useState('')
   const [Editid,setEditID]  = useState('')
   const [openId, setOpenId] = useState(null)
   const [actreq,setactreq] = useState(false)
   const [ExpPending,setExpPending]=useState(false)
   const [fileloading,setfileloading] =useState(false)
   const [newChatreq,setnewChatreq]= useState(false)
   const [ThemeMode,setThemeMode]= useState(localStorage.getItem('AgentMode'))
   const [Hidesidebar,setHidesidebar] = useState(false)
   const [Files,setFiles]=useState([])
   const [ExpDet,setExpDet]= useState({
    id:'',
    collects:''
   })
   const [ButtonDisplay,setButtonDisplay] =useState(false)
   const [Titdialogopen,setTitdialogopen] =useState(false)
   const [convTitle,setconvTitle]= useState('')
   const  endOfMessagesRef = useRef(null)
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [hoveredId,setHoveredId]=  useState('')

   const itemRefs=useRef({})
  
   const handleClick = (event,id) => {
    if (openId === id) {
        setAnchorEl(null);
        setOpenId(null);
    } else {
        setAnchorEl(event.currentTarget);
        setOpenId(id);
    }
  };

  const UpdateChangemode=()=>{
    setThemeMode(localStorage.getItem('AgentMode'))
  }
 

  useEffect(()=>{
    if (itemRefs.current[lastChatid]) {
      console.log('ran')
      itemRefs.current[lastChatid].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }},[convid]
  )

   

   const Themecolors={
    Inpbackground:ThemeMode=='Dark'?'white' :'#e5e9ef',
    ChatscreenBackground:ThemeMode=='Dark'?'#323259' :'white',
    usermessagebackground:ThemeMode=='Dark'?'#323259' :'#e9ebf0',
    chat_list_background:ThemeMode=='Dark'?'white' :'white',
    usermessagebackground:ThemeMode=='Dark'?'#323259' :'#e9ebf0'
   }
    

   const Divstyles={
    minWidth:'20%',maxWidth:'70%', marginLeft:'10px', borderRadius:'10px', textOverflow:'clip',textWrap:'wrap',wordWrap: 'break-word'
 }

   const Agstyles ={
    Chatlistcardstyles:{minWidth:'20%',width:'20%',height:'75vh', background:Themecolors.chat_list_background, justifyContent:'flex-start', display:'flex', alignItems:'center', flexDirection:'column',borderRadius:'0px'},
    Newchatbuttonstyles:{color:'#323259 !important',borderColor:'#323259 !important',fontFamily:'ui-sans-serif !important', width:'100%',fontWeight:'600 !important', },
    ChatlistDivstyles:{display:'flex',flexDirection:'column', width:'100%', justifyContent:'flex-start', alignItems:'center',overscrollBehaviorY: 'contain',overflowY:'scroll'},
    Chatnamedivstyles:{marginTop:'20px',width:'80%',display:'flex',borderRadius:'4px', border:'0.5px solid #323259'},
    Chatnamebuttonstyles:{color:'#323259 !important',borderColor:'#323259 !important',fontFamily:'ui-sans-serif !important', width:'80% !important',fontWeight:'600 !important'},
    Renamedivstyles:{marginTop:'20px',width:'80%',display:'flex',borderRadius:'4px', border:'0.5px solid #323259'},
    ConvoCardstyles:{color:'white', fontFamily:'ui-sans-serif',backgroundColor:Themecolors.ChatscreenBackground,width:'40%',flexGrow:1,height:'75vh', flexDirection:'column-reverse', display:'flex',alignItems:'flex-end',  padding:'10px',borderRadius:'0px', resize:'Horizontal', overflow:'auto'},
    Actreqdivstyles:{display:'flex', width:'100%',justifyContent:'center', },
    Convoinputdivstyles:{display:'flex', width:'100%',justifyContent:'center', padding:'20px'},
    Convoinputtextfieldstyles:{backgroundColor:Themecolors.Inpbackground, borderRadius:'10px',width:'90%', },
    ChatscrollStyles:{width:'100%',display:'flex',flexDirection:'column',overflow:'scroll', overscrollBehaviorY: 'contain'},
    Userchatdivstyles:{width:'100%',display:'flex',justifyContent:'flex-end',marginBottom:'20px'},
    AgentChatdivstyles:{width:'100%',display:'flex', flexDirection:'column',alignContent:'flex-start', flexWrap:'wrap',marginBottom:'20px',},
    MessageTextstyles:{margin:'8px',fontSize:'1rem',fontWeight:'500', padding:'2px'},
    ExpdivTextstyles:{fontSize:'1rem', color:'#323259',fontWeight:'500', margin:'0px',marginLeft:'8px'},
    iconstyles:{color:'#323259'}
   }

   

   const storage = getStorage()
   const Id = auth.currentUser.uid

   const funcc = async () => {
      const auth = getAuth();
      const { currentUser } = auth;
      const tk = await currentUser.getIdToken();

      setToken(tk)
    }

    
 

   

    useEffect(() => {
        let divElement = document.getElementsByClassName('Chatscroll')[1];
        if(divElement!=undefined){
        divElement.scrollTop = divElement.scrollHeight;
        }
      }, [currentChat]);
    

    const Fixdata=()=>{
       const Chatarr=[]
       Object.values(convodata)?.map((data)=>{
        Chatarr.push(...data)
       })
       setCurrentChat(Chatarr)
    }
    useEffect(()=>{
      Fixdata()
    },[convodata])


   const Delfun= async()=>{
    const qref= doc(db,'Agenchats', Did)
    setCurrentChat([])
    await deleteDoc(qref).then(()=>{
      setDid('')
      setDelwaropen(false)
    })
   }


   const filemessage =async()=>{
    if(Files.length>0){
    const FilesendArr=[]
    await Promise.all(Files.map(async(data)=>{
    console.log('run')
    const file = data
    const uniqueId = auth.currentUser.uid;
    const filePath = `users/${uniqueId}/Agentfileuploads/${file.name}`; 
    const fileRef = ref(storage, filePath); 
    await uploadBytes(fileRef, file); 

    FilesendArr.push({path:filePath,Name:file.name})
    }))
    console.log(FilesendArr)
    return FilesendArr
   }
   else{
    const filesarr =[]
    return filesarr
   }

  }
  
     const submitMessage = async(Data)=>{
      const auth = getAuth();
      const { currentUser } = auth;
      const tk = await currentUser.getIdToken();
        console.log('sent')
        const pathsArr=[]
        
        if(Data.files.length>0){
        await Data.files.map((data)=>{
          pathsArr.push(data.path)
        })
      }
        try{
          const sanitizedSendMsg = Data.message_text.replace(/[#"*\/']/g, '');
          console.log('sent')
        await axios.post('https://reboltapis.boltchem.com/v2/retrosynthesis/rebolt_agent',{
            "query": sanitizedSendMsg,
            "conv_id": currentChatid,
            "file_path": pathsArr,
            "tokenid": tk,
         }).then((resp)=>{console.log(resp)})
         return 'success'
         
        }
        catch(res){
            console.log(res)
        }
     }

     const Dat =new Date()
    

    
      const Convoset = async() => {

        const filesup = await filemessage()
       
         const MessageData={message_text: sendMsg,
            message_type: 'file',
            sender: 'user',
            files:filesup,
            sendat:Dat
        }
        console.log(MessageData)
        setsendMsg('')
        setFiles([])
         try {  
             const ref = doc(db, 'AgentConversationLogs', currentChatid);
             await updateDoc(ref, {
                 conversation: arrayUnion({
                    ...MessageData
                 }),
                 active_request:true
                 
             }).then(()=>{
               submitMessage(MessageData)
             })
         } catch (error) {
             console.error("Error updating document:", error);
         }
     };
     
     const Chatlastbatch = async (Doci, id) => {
      const docRef = doc(db, 'AgentConversationLogs', id);
      onSnapshot(docRef, (doc) => {
          const data = doc.data();
          setactreq(data.active_request)
         
          if (data && data.conversation) {
              setConvodata(prevState => ({
                  ...prevState,
                  [Doci]: data.conversation 
              }));
          } else {
              console.error("No conversation field found in document.");
          }
      });
  };
   
 
 console.log(actreq)

 
  const Openchat = async(chat) => {
   if(currentChatid==''){
       setcurrentChatid(chat?.reverse()[0]);
   }
    
    if (chat?.length > 0) {
        const Chatdat = {};

        await Promise.all(chat?.map(async (data, index) => {
            if (index !== chat.length-1 ) {
                console.log(data);
                const docRef = doc(db, 'AgentConversationLogs', data);

                try {
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        Chatdat[index] = [...docSnap.data().conversation];
                    } else {
                        console.log('No such document!');
                    }
                } catch (error) {
                    console.error("Error fetching document: ", error);
                }
            } else {
                Chatlastbatch(index, data);
            }
        }));

        setConvodata(Chatdat); 
       
    }
};





      const Chatlist =async ()=>{
         const q = query(collection(db, 'Agenchats'), where("uid", "==", Id));
         const Cdata = onSnapshot((q),
         (querySnapshot) => {
            const chtarr= []
            querySnapshot.forEach((y) => {
               
                chtarr.push({...y.data(),id:y.id});
            });
            console.log(chtarr)
           
            const Clist = chtarr.sort((a,b)=> b.createdAt-a.createdAt)
            console.log(Clist)
            setchatlistarr(Clist)
            if(lastChatid!=''&& lastChatid!=undefined){
               const currchat= Clist?.filter((data)=>data.id==lastChatid)
               Openchat(currchat[0].Chatfiles)
               setcurrentChatid(currchat[0].Chatfiles[0])
            }
            else{
              
               Openchat(Clist[0]?.Chatfiles)
               localStorage.setItem('lastChatid',Clist[0].id)
               setcurrentChatid(Clist[0].Chatfiles[0])
               setconvid(Clist[0].id)
            }
            setactreq(Clist[0]?.active_request)
      })
   }

   console.log(actreq)


      const createNewchat= async()=>{
          setnewChatreq(true)
           var MessageData

          const filesup = await filemessage()
            MessageData={message_text: sendMsg,
                       message_type: 'generic',
                       sender: 'user',
                       files:filesup,
                       sendat:Dat
          }
          if(filesup.length>0){
         submitMessage(MessageData)}
         else{
          if(MessageData.message_text!=''){
            submitMessage(MessageData)
          }
         }
         const q = collection(db,'AgentConversationLogs')
          const convlogreq=  await addDoc(q,{
              chatname:convTitle,
              uid:auth.currentUser.uid, 
              createdAt:serverTimestamp(),
              conversation:filesup.length>0 ?[MessageData]:MessageData.message_text==''? []:[MessageData]
          })
            
            const Chatlistref= collection(db,'Agenchats')
            
            const Chatreq =  await addDoc(Chatlistref,{
              chatname:convTitle,
              uid:auth.currentUser.uid, 
              createdAt:serverTimestamp(),
              Chatfiles:[convlogreq.id]
            })
            
            await setDoc(doc(db, 'projects',convlogreq.id),{
              createdAt: serverTimestamp(),
              projectname: convTitle,
              projectdesc: 'Agent Chat',
              owner: {
                email: auth.currentUser.email,
                uid: auth.currentUser.uid
        }

      })
      setTitdialogopen(false)
      setconvTitle('')
      setsendMsg('')
    }


     const Renamefun = async(Rid)=>{
        const qref= doc(db,'Agenchats', Rid)
        await setDoc(qref,{
            chatname:EditName
     },{ merge: true }).then(()=>{
        setEditID(null)
        setEditName(null)
     })
     }
      
     const PreRename= (name,Eid)=>{
        setEditName(name)
        setEditID(Eid)
        setTimeout(() => {
          const Elem = document.getElementsByClassName('renameelement')[0]
          if(Elem){
            Elem.focus()
          }
        },100);
     }
      
const PrInputstyles  ={
    color:'#323259',
    width: '100%',
    border: 'none',
    background: 'none',
    padding: '10px',
    fontWeight: '600'
     }


     const Changemode = ()=>{
      if(ThemeMode=='light'){
        localStorage.setItem('AgentMode','Dark')
        UpdateChangemode()
      }
      else {
        localStorage.setItem('AgentMode','light')}
        UpdateChangemode()
     }

     console.log(ThemeMode)

  
    
     useEffect(()=>{
       funcc()
       Chatlist()
       return(
        ()=>{
          localStorage.setItem('lastChatid','')
        }
       )
       
     },[])

     const cancelfun=(name)=>{
      const newFilearray = Files.filter((data)=>data.name!=name)
      setFiles(newFilearray)
     }
  
  
    const Expnamefun =(collectname)=>{
      if (collectname === 'impurities') {
        return 'Impurity Prediction';
      } else if (collectname === 'atommapping') {
        return 'Atom mapping'
      } else if (collectname === 'condition_recommendation') {
        return 'Condition recommendation';
      } else if (collectname === 'forward_reaction') {
        return 'Forward reaction';
      } else if (collectname === 'retrosynthesis') {
        return 'Retrosynthesis';
      } else if (collectname === 'retrosynthesis_tf') {
       return 'Retrosynthesis'
      } else if (collectname === 'retrosynthesis_st') {
        return 'Retrosynthesis'
      }
    }
  
  




  
  console.log(Titdialogopen)
  console.log(currentChatid)
  console.log(Files)

  
    return (
      <div style={{height:'100%'}}>
      <div >
      <div>
      <div >
      <div  style={{marginTop:'20px',display:'flex', justifyContent:'space-between', width:'10%', marginTop:'-15px'}}>
            <Tooltip title={Hidesidebar?'Show Sidebar':'Hide sidebar'}><IconButton variant='outlined' onClick={()=>{setHidesidebar(!Hidesidebar)}} sx={{color:'#323259'}}><Hideicon/></IconButton></Tooltip>
           
            <div className='Chatscroll' style={{overflowY:'scroll'}}>
                <Tooltip title="New conversation"><IconButton variant='outlined' onClick={()=>{setExpDet({id:'',
    collects:''});setTitdialogopen(true)}}><PostAddIcon sx={Agstyles.iconstyles}/></IconButton></Tooltip>
                <Tooltip title={ThemeMode == 'Dark'? 'Light mode':'Dark mode'}><IconButton variant='outlined' onClick={Changemode}>{ThemeMode == 'Dark'? <LightModeIcon sx={Agstyles.iconstyles}/>:<DarkModeIcon sx={Agstyles.iconstyles}/>}</IconButton></Tooltip>
                
            </div>
            </div>
        </div>
      </div>
      <div style={{display:'flex', gap:"5px"}}>
      {!Hidesidebar &&
            <Card sx={Agstyles.Chatlistcardstyles} elevation={0}>
              <Box sx={{ height: '40px', p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: '#323259' }}>
                Conversations
              </Box>
              <List  className='listScroll' sx={{overflowY:'scroll',height:'100%',width:'100%',maxWidth:'100%', overflowx:'hidden', textOverflow:'ellipsis'}}>
              {
                  chatlistarr.map((dat, index) => {
                    return (
                      Editid != dat.id ?
                        <ListItem
                          ref={el => (itemRefs.current[dat.id] = el)}
                          sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            backgroundColor:convid==dat.id ? "#f5f5f5":'white',
                            "&:hover": {
                              backgroundColor: "#f5f5f5", // Change background color on hover
                              cursor: "pointer", // Change the cursor to pointer on hover
                            },
                          }}
                          onMouseEnter={() => setHoveredId(dat.id)}   // Set hovered item
                          onMouseLeave={() => setHoveredId(null)}
                          secondaryAction=
                            <div style={{width:'30px'}}>
                           { hoveredId === dat.id &&
                            <>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={(e) => { handleClick(e, dat.id) }}
                              >
                                <MoreHorizIcon />
                              </IconButton>

                              <div sx={{ display: 'inline-block' }} >
                                <Popper id={dat.id} open={openId === dat.id} anchorEl={anchorEl} placement={'right-start'}>
                                  <div><Chattoolbarcomp editfun={() => { PreRename(dat.chatname, dat.id) }} delfun={() => {
                                    setDid(dat.id)
                                    setDelwaropen(true)
                                    setOpenId(null)
                                  }} /></div>
                                </Popper>
                              </div>
                            </>
                           }
                           </div>
                          >
                          <ListItemText primary={dat.chatname}  onClick={() => {
                            setExpDet({id:'',collects:''})
                            Openchat(dat.Chatfiles);
                            setcurrentChatid(dat.Chatfiles[0])
                            setconvid(dat.id)
                            localStorage.setItem('lastChatid',dat.id)
                            
                            console.log(dat.id)
                          }} 
                            sx={{maxWidth:'100%', overflow:'hidden',textOverflow:'ellipsis',whiteSpace: 'nowrap'}}
                          />
                        
                        </ListItem> :
                        <TextField className='renameelement' size="small" fullWidth value={EditName} onChange={(e) => setEditName(e.target.value)} onKeyDown={(e) => {
                          if (e.key == 'Enter') {
                            Renamefun(dat.id)
                          }
                        }} />
                    )
                  })
                }

                {/* <div style={Agstyles.Renamedivstyles}>
                  <input className='renameelement' style={PrInputstyles} value={EditName} onChange={(e) => setEditName(e.target.value)} onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      Renamefun(dat.id)
                    }
                  }} />
                </div> */}
              </List>
            </Card>
          }

         <Card sx={Agstyles.ConvoCardstyles} >
           
         {
          actreq ? <div style={Agstyles.Actreqdivstyles}><ActivereqComponent/></div>
          :<div style={Agstyles.Convoinputdivstyles}>
          <div style={Agstyles.Convoinputtextfieldstyles}>
          {
            Files.length>0 &&
            <div style={{display:'flex',margin:'10px', flexWrap:'wrap',width:'100%'}}>
             {Files.map((data)=>(
              <FileDisplay fileName={data.name} onRemove={()=>{cancelfun(data.name)}} Removebutton={true}/>
             ))
             }
            </div>
          }
          <div style={{display:'flex',justifyContent:'space-between'}}>
          <IconButton onClick={()=>{
              document.getElementById('Chatfileinp').click()
           }}><AttachFileIcon sx={Agstyles.iconstyles}/></IconButton>
          <input
           style={{background:'none', border:'none', outline:'none', width:'80%'}}
           disabled={actreq}
           onChange={(e)=>{
              setsendMsg(e.target.value)
           }}
           onKeyDown={(event)=>{
             console.log(event.key)
             if(event.key=='Enter'){
              if(currentChatid!='' && currentChatid!=undefined){
                if(Files.length>0){
                Convoset()
                }
                else{
                  if(sendMsg!=''){
                    Convoset()
                  }
                }
                }
              else{
                if(sendMsg!=''){
                setTitdialogopen(true)
                }
              }
             }
           }}
           value={sendMsg}
              />
              <IconButton onClick={()=>{
              if(currentChatid!='' && currentChatid!=undefined){
                if(Files.length>0){
                Convoset()
                }
                else{
                  if(sendMsg!=''){
                    Convoset()
                  }
                }
                }
              else{
                setTitdialogopen(true)
              }
           }}><SendIcon sx={Agstyles.iconstyles}/></IconButton>
           </div>
           <input id='Chatfileinp' onChange={(e)=>{setFiles(Object.values(e.target.files))}} style={{display:'none'}} type='file' multiple />
         </div>
        </div> 
         }
         <div className='Chatscroll' style={Agstyles.ChatscrollStyles}>{
        currentChat.map((data,index)=>
          {


         console.log(data.msg!=undefined )
          return(
          data.sender=='user' ?   
          
           <div key ={index} style={Agstyles.Userchatdivstyles}>
                  <div style={{...Divstyles, color:'#323259',backgroundColor:ThemeMode== 'dark'? 'white':'#e9ebf0', margin:'10px'}}><p style={Agstyles.MessageTextstyles} >{data.message_text}</p>
                  {
                   data?.files?.length>0 &&
                    data?.files?.map((dt)=>(
                    <FileDisplay fileName={dt.Name} Removebutton={false}/>
                  ))
                  }
                  
                  </div>
                  
     
            </div>
          :
          data.message_type=='generic'?
          <div style={{width:'100%', display:'flex',alignItems:'center',padding:'2px'}}>
                 
          <div key ={index} style={Agstyles.AgentChatdivstyles}>
                  <div  style={{...Divstyles, color:'#323259',backgroundColor:'white', display:'flex'}}>
                  <SupportAgentIcon sx={{margin:'10px',...Agstyles.iconstyles}}/>
                  <p style={Agstyles.MessageTextstyles} >
                  <BoldText text={data.message_text} />
                  </p>
                  </div>
          </div>
          </div>
          :
          <div key ={index} style={Agstyles.AgentChatdivstyles}>
          
                  <div  style={{...Divstyles,backgroundColor:'white'}}>
                  <div style={{width:'100%', display:'flex',alignItems:'center',padding:'2px'}}>
                  <SupportAgentIcon sx={{margin:'10px',...Agstyles.iconstyles}}/>
                  <div>
                  <p style={Agstyles.ExpdivTextstyles} >{data.message_text}</p>
                  {
                    data?.exp_info?.map((dats,index)=>(
                 
                  
                  <div style={{display:'flex',alignItems:'center',padding:'2px'}}>
                  <p style={Agstyles.ExpdivTextstyles} >{ data?.exp_info?.length>1 ? `step-${index+1} Output`: 'Output'}</p>
                  <IconButton onClick={()=>{
                      setExpDet({
                          id:dats?.experiment_id,
                          collects:dats?.collection_name
                      })
                      setExpdialogopen(true)}}>< RemoveRedEyeIcon sx={Agstyles.iconstyles}/></IconButton>
                      </div>
                  
                 
                      ))
                  }
                  </div>
                  </div>
                  </div>
            
         
          </div>

         )} 
         
          )
         }
         </div> 
         </Card>
         
         {
          ExpDet.id!='' &&
          <div style={{backgroundColor:Themecolors.chat_list_background,flexGrow: 1,width:'40%', minWidth:'40%',  height:'75vh'}}>
          <CloseIconbutton onClick={()=>{setExpDet({id:'',
          collects:''})}}/>
        
          <div className='Chatscroll' style={{backgroundColor:Themecolors.chat_list_background,flexGrow: 1,width:'100%', minWidth:'100%', overflow:'scroll', height:'100%'}}>
         
          <Outputfun collects={ExpDet.collects} Expid={ExpDet.id}/>
         
         </div>
         </div>
         }
        
      </div>
      </div>
      {/* <DialogBox 
          closefunction= {()=>{
              setExpdialogopen(false)
          }}
          Title='Experiment Output'
          content={<Outputfun collects={ExpDet.collects} Expid={ExpDet.id}/>}
          status={Expdialogopen}
      /> */}
      <DeleteWarning  DeleteOpen={Delwaropen} closeDeleteDialog={()=>{setDelwaropen(false)}} Delfunction={()=>{Delfun()}}/>
      <Dialog open={Titdialogopen}>
        <DialogTitle style={{padding:'0px'}}><CloseIconbutton onClick={()=>{setTitdialogopen(false);setconvTitle('')}}/></DialogTitle>
        <DialogContent sx={{display:'flex',justifyContent:'center', flexDirection:'column', alignItems:'center', gap:'10px'}} >
        
       <Typography color='blue' sx={{textAlign:'center'}}>You are initiating new conversation please provide a conversation title, a project is created with same title</Typography>
        <Typography  sx={{marginBottom:'10px',color:'#323259', fontSize:'1rem',fontWeight:'bold',width:'60%',textAlign:'left'}}>
          Conversation title
        </Typography>
        <TextField
          placeholder="Enter title"
          value={convTitle}
          onChange={(e) => setconvTitle(e.target.value)}
          sx={{width:'60%'}}
        />
    
        </DialogContent>
        <DialogActions sx={{marginRight:'20%'}}>
          <ColorButton onClick={createNewchat}>
            Create
          </ColorButton>
        </DialogActions>
      </Dialog>
     
      </div>
)
}

export default AgentsScreen2