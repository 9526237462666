import MoleculeStructure from "views/RDkit/MoleculeStructure";
import html2pdf from 'html2pdf.js';
import Plus from '../../../assets/images/icons/plus.png'
import Implies from '../../../assets/images/icons/implies.png'
import logo from '../../../assets/images/icons/logo.jpeg'
import React from 'react'
const OBJ =  []

const refine1 = (atta)=>{
let Ch = []
atta.children.map((data)=>{
  Ch.push(data.smile)
})
 
 OBJ.push({smile:atta.smile, child1:Ch,procedure:atta.procedure[0],lnk:atta.uspto_link[0],
  reaction_class:atta.reaction_class,
  reaction_name: atta.reaction_name,                                                 
  step_yield:atta.step_yield, 
  fwd_score:atta.fwd_score,  
  })
 atta.children.map((X)=>{
 
 if(X.children!=undefined){
 X.children.map((Y)=>{refine1(Y)})
 }}) 

}

const refine2 = (nData)=>{
  
  if(nData.children!==undefined){
    let Ch=[]
    nData.children.map((data)=>{Ch.push(data.smile)})
  OBJ.push({smile:nData.smile, child1:Ch})}
  if(nData.children!==undefined){
  nData.children.map((X)=>{
    
    refine2(X)})
  } 
 }
 





const DwnSVG = ()=>{
 // Select the original SVG element and clone it
const con = document.querySelector('.rd3t-tree-container');
const originalSvg = con.querySelector('svg');
const svg = originalSvg.cloneNode(true);

// Function to copy computed styles from sourceElement to targetElement
function copyStyles(sourceElement, targetElement) {
    const computedStyles = window.getComputedStyle(sourceElement);
    for (let i = 0; i < computedStyles.length; i++) {
        const key = computedStyles[i];
        targetElement.style[key] = computedStyles.getPropertyValue(key);
    }
}

// Apply styles to the cloned SVG and its children
copyStyles(originalSvg, svg);
const originalElements = originalSvg.querySelectorAll('*');
const clonedElements = svg.querySelectorAll('*');
originalElements.forEach((el, index) => copyStyles(el, clonedElements[index]));

// Remove specific elements from the cloned SVG
const g = svg.querySelector('.rd3t-g');
g.querySelectorAll('.MuiButtonBase-root').forEach(el => el.remove());
g.querySelectorAll('.MuiSvgIcon-root').forEach(el => el.remove());
console.log(g)

// Set additional attributes if necessary
svg.setAttribute('fill', 'none');
svg.setAttribute('stroke', 'black');
svg.setAttribute('alignItems', 'center');
svg.setAttribute('transform', '');

// Serialize the modified SVG to XML
const svgAsXML = new XMLSerializer().serializeToString(svg);
const svgData = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgAsXML)}`;

// Create a download link and trigger the download
const link = document.createElement("a");
document.body.appendChild(link);
link.setAttribute("href", svgData);
link.setAttribute("download", "rebolt_path.svg");
link.click();

// Clean up after download
document.body.removeChild(link);

}  












 const DwnPdf = ({treedata, template })=>{
 
  OBJ.length=0


if (template=='retrosynthesis'|| template=="path_builder"){
 let ARR = [];
 ARR.push({...treedata.path.children[0]})


 ARR.map((data)=>{
  refine1(data)
 })
 }
 else{
 let ARRA = []
 ARRA.push({...treedata.path})
 ARRA.map((data)=>{
  refine2(data)
 })

 }

 return (
<div id='Downdata' >
  <img   src= {logo}  wdith='80px' height='80px' style= {{position:'relative',left:'300px', top:'20px',marginBottom:'20px'}}/>
<div>{ (template=='retrosynthesis') ?
  <table style= {{marginLeft:'30px',marginTop:'30px',marginBottom:'30px'}} >
  <tbody>
   <tr><td><b>Target Molecule:</b></td><td>{treedata.path.smile}</td></tr>
   <tr><td><b>Estimated Cost:</b></td><td>${treedata.path.path_cost}</td></tr>
   <tr><td><b>Estimated Yield:</b></td><td>{treedata.path.path_yield}</td></tr>
   <tr><td><b>number of steps:</b></td><td>{treedata.path.num_steps}</td></tr>
   </tbody> 
   </table>
   :
   <table style= {{marginLeft:'30px',marginTop:'30px',marginBottom:'30px'}} >
  <tbody>
   <tr><td><b>Target Molecule:</b></td><td>{treedata.path.smile}</td></tr>
   <tr><td><b>number of steps:</b></td><td>{treedata.path.num_steps}</td></tr>
   </tbody> 
   </table>
   } 
</div>

{

OBJ.reverse().map((data,index) =>{


  console.log(data)

return(
<div key={`${data.smile}${index}`}>  
<div  style={{marginLeft:'30px'}} ><h3>Step {index+1}</h3></div>
<div  className='SML' style={{display:'flex',marginLeft:'20px', flexDirection:'row', justifyContent: 'space-around',flexWrap: 'wrap'}} > 
   

 { 
   data.child1.map((Mol,index)=>{
   return (
   <React.Fragment key={index}>
    <MoleculeStructure width={150} height={100} svgMode structure={Mol}/>
    {(index < data.child1.length-1) &&
    <img  src={Plus} width='30px' height='30px' alt='plus'style= {{position:'relative',top:'40px'}}/> }
    </React.Fragment>)
   })

   }
   <img src={Implies} width='50px' height='30px' alt ='implies' style= {{position:'relative',top:'45px'}}/> 
   <MoleculeStructure width={150} height={100} svgMode structure={data.smile}/>
    
</div>
<div>
  { (template=='retrosynthesis') &&
  <>
  <p style={{textAlign: 'justify',marginLeft:'30px',}}><span style={{fontWeight:'bold'}}>Reaction  class:</span><span> {data.reaction_class}</span> </p> 
  <p style={{textAlign: 'justify',marginLeft:'30px',}}><span style={{fontWeight:'bold'}}>Reaction name:</span><span> {data.reaction_name}</span> </p> 
  <p style={{textAlign: 'justify',marginLeft:'30px',}}><span style={{fontWeight:'bold'}}>Step yield:</span><span> {isNaN(data.step_yield)?data.step_yield: Number(data.step_yield).toFixed(2)}</span> </p> 
  <p style={{textAlign: 'justify',marginLeft:'30px',}}><span style={{fontWeight:'bold'}}>Forward score:</span><span> {isNaN(data.fwd_score)?data.fwd_score: Number(data.fwd_score).toFixed(2)}</span> </p> 
  <p style={{textAlign: 'justify',marginLeft:'30px'}}><span style={{fontWeight:'bold'}}>Reference:</span> {data.procedure}</p> 
  <p style={{textAlign: 'justify',marginLeft:'30px',}}>Reference link:<span> <a  href={data.lnk}>{data.lnk}</a></span> </p> 
   </>
  }
</div>
</div>
 ) })
}
</div> 
 )
 }


 
 
  
 const Downpdf = ()=>{
const options = {
  filename: 'pathway',
  margin:0.25,
  image: { type: 'jpeg', quality: 1 },
  html2canvas: { scale: 2 },
  pagebreak: { mode: 'avoid-all'},
  jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', overflow:'next page' }
};

html2pdf(document.getElementById('Downdata'),options)

}
 
 export {Downpdf,DwnSVG}
 export default DwnPdf 